<template>
  <v-row class="topMenu" v-if="!mdAndDown">
    <v-col>
      <v-divider class="top-divider" thickness="2"></v-divider>
    </v-col>
    <v-col>
      <v-container class="floating-container" ref="">
        <v-toolbar height="auto" class="elevation-4" dense floating>
          <v-row v-if="!items.length && loading">
            <v-col cols="2" v-for="el in [1, 2, 3, 4, 5, 6]" :key="el">
              <v-skeleton-loader
                height="45"
                width="100%"
                class="rounded"
                color="#303030"
              />
            </v-col>
          </v-row>
          <div v-else-if="!items.length && !loading" class="text-white">
            {{ $t('Menu items not found') }}
          </div>
          <v-menu
            v-for="(item, key) in items"
            :key="key"
            transition="slide-y-transition"
            offset-y
            :attach="disableTeleportation"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                class="btn-dark"
                v-bind="props"
                :class="{
                  active: isActive(item),
                  activeSubMenuWidth:
                    item.children?.length &&
                    props?.['aria-expanded'] === 'true',
                }"
                @click="
                  item.children?.length
                    ? null
                    : navigateToUrl(localePath(item.route_url))
                "
                data-test="navButton"
              >
                <v-badge
                  v-if="item.badge"
                  :content="item.badge"
                  size="x-small"
                  rounded="md"
                >
                  <v-icon left class="mr-3">mdi-{{ item.icon }} </v-icon>
                </v-badge>
                <v-icon v-else left class="mr-3">mdi-{{ item.icon }} </v-icon>
                {{ item.verbose_name }}
                <v-icon
                  v-if="item.children && item.children.length"
                  right
                  class="ml-3"
                >
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </template>

            <v-list
              v-if="item.children && item.children.length"
              bg-color="#303030"
              class="payoutSubMenuItems"
            >
              <v-list-item v-for="(child, i) in item.children" :key="i">
                <v-list-item-title
                  @click="navigateToUrl(localePath(child.route_url))"
                  data-test="navList"
                  :class="{
                    active: isActive(child),
                  }"
                >
                  <v-badge
                    v-if="child.badge"
                    :content="child.badge"
                    size="x-small"
                    rounded="md"
                  >
                    <v-icon left class="mr-3">mdi-{{ child.icon }} </v-icon>
                  </v-badge>
                  <v-icon v-else left class="mr-3"
                    >mdi-{{ child.icon }}
                  </v-icon>
                  {{ child.verbose_name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-container>
    </v-col>
    <v-col>
      <v-divider class="top-divider" thickness="2"></v-divider>
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import { PropType, ref } from 'vue';
import { navigateToUrl } from 'single-spa';
import { localePath } from '@/composables/index';
import { isActive } from '@/composables/index';
import { NavigationItems } from '../interface/navigation';
import { useDisplay } from 'vuetify';

const { mdAndDown } = useDisplay();
defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  items: {
    type: Array as PropType<NavigationItems[]>,
    required: true,
  },
});
const disableTeleportation = ref(false);

defineExpose({
  disableTeleportation,
});
</script>

<style scoped lang="scss">
.topMenu {
  .top-divider {
    margin-top: 46px !important;
    height: 5px;
    width: auto;
    margin: auto;
  }
  .v-toolbar {
    padding: 8px;
    background: #1e1e1e;
    border-radius: 12px;
    :deep(.v-toolbar__content) {
      justify-content: center;
      gap: 8px;
    }
  }
  .floating-container {
    width: 1087px;
    @media (min-width: 1600px) {
      width: 1096px;
    }
    @media (min-width: 1680px) {
      width: 1155px;
    }
    @media (min-width: 1920px) {
      width: 1315px;
    }
    @media (min-width: 2048px) {
      width: 1401px;
    }
    @media (min-width: 2560px) {
      width: 1754px;
    }
    @media (min-width: 2700px) {
      width: 1849px;
    }
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    .btn-dark {
      flex-basis: 0;
      flex-grow: 1;
      width: 100px;
      margin: 0;
      padding: 0;
      height: 44px !important;
      border-radius: 8px;
      background-color: #303030;
      color: white;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: normal;
      text-transform: capitalize;
      transition: width 0.3s ease;
      &.active {
        background-color: #f77d1a;
        :deep(.v-badge__badge) {
          background-color: transparent;
          border: 1px solid white;
          font-weight: 500;
        }
      }
      &.activeSubMenuWidth {
        flex-basis: unset;
        width: 185px;
      }
      :deep(.v-btn__overlay) {
        opacity: 0 !important;
      }
    }
    :deep(.v-badge__badge) {
      padding: 0;
      left: 12px !important;
      bottom: 12px !important;
      font-size: 10px;
      min-height: 16px !important;
      min-width: 16px !important;
      max-height: 16px !important;
      max-width: 16px !important;
    }
  }
}
:deep(.v-list-item-title) {
  cursor: pointer;
}
:deep(.v-list-item-title:hover) {
  color: #f77d1a;
}
</style>
<style lang="scss">
.payoutSubMenuItems {
  padding: 0 !important;
  .v-list-item {
    &__content {
      height: 100%;
      .v-list-item-title {
        height: 100%;
        display: flex;
        align-items: center;
        &.active {
          color: #f77d1a !important;
          font-weight: 400 !important;
        }
        .v-badge__badge {
          padding: 0;
          left: 16px !important;
          bottom: 14px !important;
          font-size: 10px;
          min-height: 16px !important;
          min-width: 16px !important;
          max-height: 16px !important;
          max-width: 16px !important;
        }
      }
    }
  }
}
</style>
