import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';

const API_ORIGIN = localStorage.getItem('VUE_APP_PAYOUT_SERVER_ORIGIN');
const GRAPHQL_API_URL = API_ORIGIN + '/graphql/';

const httpLink = new HttpLink({
  uri: GRAPHQL_API_URL || '',
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('VUE_APP_TOKEN');
  const timezone = localStorage.getItem('userTimeZone');
  const merchant = localStorage.getItem('MERCHANT_ID');
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      'X-TIMEZONE': timezone || '',
      'X-MERCHANT': merchant || '',
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
