<template>
  <v-app
    id="payout_frontend"
    class="p-b-50"
    :class="mdAndDown ? 'p-t-70' : 'p-t-140'"
  >
    <router-view />
  </v-app>
</template>

<script setup>
import { onMounted, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { i18n } from './plugins/i18n';
import { useAuthStore } from '@/store/index';
import { useLocale } from 'vuetify';
import { useRoute } from 'vue-router';
import { useDisplay } from 'vuetify';

const { mdAndDown } = useDisplay();
const authStore = useAuthStore();
const route = useRoute();
const i18nUse = useI18n();

const props = defineProps([
  'idToken',
  'logout',
  'arabicTranslation',
  'translations',
  'sentryUrl',
  'sentrySampleRate',
  'ignoredSentryErrors',
  'checkSentryErrors',
  'isLocal',
]);

const { current } = useLocale();

const checkLanguage = (path) => {
  if (path.length > 3) {
    const lang = path.slice(1, 3);
    if (i18n.global.locale.value === lang) return;
    if (lang === 'en' || lang === 'ar') {
      i18n.global.locale.value = lang;
      current.value = i18n.global.locale.value;
    }
  }
};

const setLang = () => {
  const translations = props.translations();

  i18nUse.setLocaleMessage('en', translations.en || {});
  i18nUse.setLocaleMessage(
    'ar',
    { ...props.arabicTranslation, ...translations.ar } || {},
  );
};

watchEffect(() => {
  if (route?.path) {
    checkLanguage(route.path);
  }
});

onMounted(() => {
  setLang();
  authStore.setToken(props.idToken());
  authStore.setLogout(props.logout);
});
</script>

<style lang="scss" scoped>
:deep(.v-container) {
  max-width: 1087px;
  @media (min-width: 1200px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (min-width: 1600px) {
    max-width: 1096px;
  }
  @media (min-width: 1680px) {
    max-width: 1155px;
  }
  @media (min-width: 1920px) {
    max-width: 1315px;
  }
  @media (min-width: 2048px) {
    max-width: 1401px;
  }
  @media (min-width: 2560px) {
    max-width: 1754px;
  }
  @media (min-width: 2700px) {
    max-width: 1849px;
  }
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-t-140 {
  padding-top: 140px;
}
.p-t-70 {
  padding-top: 70px;
}
.v-theme--light {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --v-hover-opacity: 0.04;
  --v-theme-overlay-multiplier: 1;
}
</style>
