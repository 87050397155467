<template>
  <v-theme-provider theme="dark" with-background>
    <v-toolbar height="72">
      <div class="d-flex justify-center align-center">
        <v-icon
          v-if="mdAndDown"
          icon="mdi-menu"
          class="menu-icon"
          @click="$emit('toggleDrawer')"
        ></v-icon>
        <img
          src="../assets/images/ottu-logo.png"
          alt="Ottu"
          class="ottu-logo"
        />
      </div>
      <div v-if="smAndUp" class="w-33">
        <v-text-field
          density="compact"
          placeholder="Search..."
          prepend-inner-icon="mdi-magnify"
          variant="solo"
          hide-details="auto"
        ></v-text-field>
      </div>
      <div class="d-flex justify-center align-center header-info">
        <v-select
          v-model="selectedTimezone"
          :items="timezoneCountries"
          item-title="name"
          item-value="timezone"
          class="timezone-dropdown"
          aria-label="Select Timezone"
          role="listbox"
          variant="solo-filled"
          hide-details
        >
          <template v-slot:prepend-item>
            <v-list-item
              title="Time Zone"
              min-height="30px"
              class="text-center pa-1"
            />
            <v-divider class="ml-4 mr-4"></v-divider>
          </template>
          <template v-slot:selection="{ item }">
            <img
              v-if="item.title"
              :src="require(`@/assets/images/Flags/${item.title}.png`)"
              alt="Timezone image"
              v-tooltip="
                $t(
                  'Select the correct timezone to display records and generate reports accurately.',
                )
              "
            />
          </template>
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-if="item.title"
              v-bind="props"
              :title="$t(item.title)"
              class="timezone-list"
              color="#f77d1a"
              @click="setTimezone()"
            >
              <img
                :class="[i18n.global.locale.value === 'en' ? 'mr-3' : 'ml-3']"
                :src="require(`@/assets/images/Flags/${item.title}.png`)"
              />
            </v-list-item>
          </template>
        </v-select>
        <v-menu
          transition="slide-y-transition"
          location="bottom center"
          offset-y
          :attach="disableTeleportation"
          class="notification-menu"
        >
          <template v-slot:activator="{ props }">
            <v-badge
              v-if="smAndUp"
              :content="dummyNotifications.length"
              size="small"
              bordered
              v-bind="props"
              rounded="md"
            >
              <v-btn
                icon="mdi-bell"
                variant="tonal"
                class="notification-menu-btn"
              />
            </v-badge>
          </template>
          <v-card max-width="509px" class="pa-5">
            <v-list-item
              :title="$t('Notifications')"
              min-height="30px"
              class="text-center pl-2 pr-2 pb-2 notification-menu-heading"
            />
            <v-divider class="ml-6 mr-6"></v-divider>

            <v-list min-width="250">
              <v-list-item
                v-for="(item, i) in dummyNotifications"
                :key="i"
                :value="item"
                color="primary"
                rounded="lg"
              >
                <template v-slot:prepend>
                  <v-btn
                    variant="outlined"
                    color="#f77c1a"
                    :icon="item.icon"
                    style="background: white"
                  />
                </template>

                <v-list-item-title
                  v-text="item.title"
                  class="pl-4 notification-menu-title"
                ></v-list-item-title>
              </v-list-item>
            </v-list>

            <v-divider class="ml-6 mr-6"></v-divider>
            <v-list-item
              :title="$t('View All')"
              min-height="30px"
              class="text-center pl-2 pr-2 pt-2 notification-menu-heading"
            />
          </v-card>
        </v-menu>
        <v-divider v-if="smAndUp" vertical thickness="4"></v-divider>
        <div class="user-menu">
          <img
            v-if="smAndUp"
            src="../assets/images/Frame 457.png  "
            class="user-img"
          />
          <div>{{ userDetails.username }}</div>
          <v-menu
            transition="slide-y-transition"
            offset-y
            :attach="disableTeleportation"
            data-test="user-menu"
          >
            <template v-slot:activator="{ props }">
              <v-btn icon v-bind="props" data-test="chevron-button">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card
              v-if="userDetails.username === undefined"
              class="action-menu-loader"
            >
              <v-skeleton-loader
                max-width="250"
                type="list-item-avatar@4"
              ></v-skeleton-loader>
            </v-card>
            <v-card v-else>
              <v-list min-width="250">
                <v-list-item
                  class="menu-item"
                  max-width="250px"
                  v-if="userDetails.is_superuser && merchantDetails.admin_url"
                  :href="merchantDetails.admin_url"
                >
                  <v-list-item-title class="text-center"
                    >{{ $t('Administration panel')
                    }}<v-icon
                      :class="[
                        i18n.global.locale.value === 'en' ? 'pl-6' : 'pr-6',
                      ]"
                      icon="mdi-cog-outline"
                  /></v-list-item-title>
                </v-list-item>
                <v-list-item
                  class="menu-item"
                  max-width="250px"
                  @click="switchLanguage(route, router)"
                >
                  <v-list-item-title class="text-center"
                    >{{ $t('Switch language')
                    }}<v-icon
                      :class="[
                        i18n.global.locale.value === 'en' ? 'pl-6' : 'pr-6',
                      ]"
                      icon="mdi-web"
                  /></v-list-item-title>
                </v-list-item>

                <v-list-item
                  class="menu-item"
                  :to="{ path: localePath('/user') }"
                  max-width="250px"
                >
                  <v-list-item-title class="text-center"
                    >{{ $t('Account settings')
                    }}<v-icon
                      :class="[
                        i18n.global.locale.value === 'en' ? 'pl-6' : 'pr-6',
                      ]"
                      icon="mdi-account-box"
                  /></v-list-item-title>
                </v-list-item>

                <v-list-item
                  class="menu-item"
                  max-width="250px"
                  @click="logout()"
                >
                  <v-list-item-title class="text-center"
                    >{{ $t('Logout')
                    }}<v-icon
                      :class="[
                        i18n.global.locale.value === 'en' ? 'pl-6' : 'pr-6',
                      ]"
                      icon="mdi-logout"
                  /></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </div>
    </v-toolbar>
  </v-theme-provider>
</template>

<script setup lang="ts">
import { i18n } from '@/plugins/i18n';
import { useAuthStore, useConfigStore } from '@/store';
import {
  useProcessReq,
  switchLanguage,
  localePath,
  dispatchSnackbar,
} from '@/composables/index';
import { useRouter, useRoute } from 'vue-router';
import { onMounted, ref, nextTick } from 'vue';
import NavigationService from '@/services/merchantNavigation';
import { useDisplay } from 'vuetify';

const { mdAndDown, smAndUp } = useDisplay();
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const configStore = useConfigStore();
const processReq = useProcessReq();

const userDetails = authStore.getUser;

defineEmits<{
  (e: 'toggleDrawer'): void;
}>();

const disableTeleportation = ref(false);
interface MerchantDetails {
  admin_url: string;
  logo_url: string;
  favicon_url: string;
  title: string;
  title_en: string;
  title_ar: string;
  subheader: string;
}

let merchantDetails: MerchantDetails = {
  admin_url: '',
  logo_url: '',
  favicon_url: '',
  title: '',
  title_en: '',
  title_ar: '',
  subheader: '',
};
const timezoneCountries = ref([
  { name: 'Kuwait', timezone: 'Asia/Kuwait' },
  { name: 'Saudi Arabia', timezone: 'Asia/Riyadh' },
  { name: 'Bahrain', timezone: 'Asia/Bahrain' },
  { name: 'Oman', timezone: 'Asia/Muscat' },
  { name: 'Qatar', timezone: 'Asia/Qatar' },
  { name: 'Egypt', timezone: 'Africa/Cairo' },
  { name: 'India', timezone: 'Asia/Kolkata' },
  { name: 'UAE', timezone: 'Asia/Dubai' },
]);

const dummyNotifications = ref([
  {
    title:
      'Lorem ipsum dolor sit amet consectetur. Nulla semper fringilla vestibulum duis malesuada accumsan.',
    icon: 'mdi-clipboard-check-outline',
  },
  {
    title: 'Sed posuere ante ac ex varius, vel ultricies justo convallis.',
    icon: 'mdi-bank-outline',
  },
  {
    title: 'Fusce vitae libero in nibh vulputate placerat.',
    icon: 'mdi-receipt-text-outline',
  },
  {
    title: 'Vestibulum nec magna eget odio interdum scelerisque.',
    icon: 'mdi-clipboard-check-outline',
  },
]);
const selectedTimezone = ref('');

const logout = () => {
  authStore.getLogout?.();
};

const setMerchantConfigs = (data: MerchantDetails) => {
  const {
    admin_url,
    logo_url,
    favicon_url,
    title,
    title_en,
    title_ar,
    subheader,
  } = data;
  merchantDetails = {
    admin_url,
    logo_url,
    favicon_url,
    title,
    title_en,
    title_ar,
    subheader,
  };
};

const isUserTimeZoneNotAvailableinStorage = () => {
  let userTimeZone = localStorage.getItem('userTimeZone');
  if (userTimeZone) selectedTimezone.value = userTimeZone;
  return userTimeZone === 'null';
};

const setCountryInDropdown = (country: string) => {
  let countryTemp = country
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  let userCountry = timezoneCountries.value.find((item) => {
    return countryTemp === item.name;
  });
  let text;
  let color;
  if (userCountry) {
    text = `Timezone set to ${userCountry.name}`;
    color = 'success';
    selectedTimezone.value = userCountry.timezone;
  } else {
    text = `Couldn't match your country. Defaulted to Kuwait. You can change it from the dropdown.`;
    color = 'error';
    selectedTimezone.value = timezoneCountries.value[0].timezone;
  }
  dispatchSnackbar(text, color);
  localStorage.setItem('userTimeZone', selectedTimezone.value);
};

const setTimezone = async () => {
  if (selectedTimezone.value) {
    try {
      await processReq(
        NavigationService.setTimeZone({ timezone: selectedTimezone.value }),
        false,
        true,
      );
      localStorage.setItem('userTimeZone', selectedTimezone.value);
    } catch (err) {
      console.error('Failed to set timeZone:', err);
    }
  }
};

onMounted(async () => {
  const configData = configStore.getConfig as MerchantDetails;
  setMerchantConfigs(configData);
  await nextTick();
  if (isUserTimeZoneNotAvailableinStorage()) {
    setCountryInDropdown('');
  }
});
defineExpose({
  disableTeleportation,
});
</script>

<style lang="scss" scoped>
.menu-item :hover {
  color: #f77c1a;
  font-weight: 700;
}
.notification-menu {
  &-btn {
    width: 44px !important;
    height: 44px !important;
    border-radius: 8px;
  }
  &-heading {
    :deep(.v-list-item-title) {
      font-weight: 700;
      font-size: 20px;
    }
  }
  .notification-menu-title {
    white-space: break-spaces;
  }
  .v-list-item {
    font-size: 14px;
    padding-inline: 0px !important;
    &:hover {
      .v-list-item-title {
        font-weight: 700;
      }
    }
  }
}
.v-toolbar {
  background: black;
  :deep(.v-toolbar__content) {
    justify-content: space-between;
  }
}
.timezone-list {
  :deep(.v-list-item__content) {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row-reverse;
  }
  :deep(img) {
    width: 24px;
  }
}
.timezone-dropdown {
  :deep(.v-select__selection img) {
    width: 28px;
  }
  :deep(.v-field) {
    padding: 0;
    color: transparent;
  }
  :deep(.v-field__input) {
    padding: 8px;
    padding-right: 0;
  }
  :deep(.v-select__menu-icon) {
    margin: 0;
    color: white;
  }
  :deep(.v-list-item-title img) {
    width: 45px;
  }
}

.ottu-logo {
  padding-left: 3rem;
  margin-right: 10px;
  height: 40px;
  @media screen and (max-width: 1280px) {
    padding-left: 1rem;
  }
  @media screen and (max-width: 420px) {
    height: 30px;
    padding-left: 0.5rem;
    margin-right: 8px;
  }
}
.menu-icon {
  padding-left: 10px;
}
.v-input {
  :deep(.v-field__input) {
    min-height: auto;
  }
}
.header-info {
  gap: 1rem;
  padding-right: 3rem;
  @media screen and (max-width: 991px) {
    padding-left: 0;
    padding-right: 0;
    gap: 2px;
  }
  :deep(.v-badge__badge) {
    padding: 0;
  }
  .user-menu {
    display: flex;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: 420px) {
      gap: 0px;
    }
  }
  .user-img {
    height: 40px;
    width: 40px;
    border-radius: 6px;
  }
}
</style>
